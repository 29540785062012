@tailwind base;
@tailwind components;
@tailwind utilities;

.password-input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #e1e5ea !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.prestudy-form .sidebar-item {
  font-size: 14px !important;
}

.accordian_search > .MuiPaper-rounded,
.accordian__multi__select .multiselect-container {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border: 1px solid #c3ccd5 !important;
  box-shadow: none !important;
  /* max-width: 235px !important; */
  width: 100% !important;
}

.accordian_search > .MuiPaper-rounded,
.accordian__calendar .multiselect-container {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border: 1px solid #c3ccd5 !important;
  box-shadow: none !important;
  width: 100% !important;
}

.accordian_search > .MuiPaper-rounded > div > input:focus,
.accordian__multi__select
  .multiselect-container
  > .search-wrapper
  > input:focus,
.accordian__calendar input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.accordian__multi__select .multiselect-container > .search-wrapper,
.accordian__calendar .multiselect-container > .search-wrapper {
  border: none !important;
  width: 100% !important;
}
.open__select .multiselect-container {
  min-width: 335px !important;
  width: 100% !important;
}

/* Pagination Button */
.pagination__button button {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}
.pagination__button button.MuiPaginationItem-rounded {
  background-color: #fff !important;
  color: #0b0d0f !important;
  font-weight: 600 !important;
}
.pagination__button button.Mui-selected {
  background-color: #0a4a5e !important;
  color: #fff !important;
}

.select__control {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  padding: 7px !important;
  box-shadow: none !important;
  border-color: #c3ccd5 !important;
}
.select__indicator-separator {
  display: none !important;
}
.select__input {
  opacity: 0 !important;
}

.filter__accordian > .MuiPaper-rounded {
  border: 1px solid transparent !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.filter__accordian > .Mui-expanded {
  border: 1px solid #e39d6b !important;
}

/* Header Profile Dropdown */
.MuiIconButton-root {
  border-radius: 0% !important;
  -webkit-border-radius: 0% !important;
  -moz-border-radius: 0% !important;
  -ms-border-radius: 0% !important;
  -o-border-radius: 0% !important;
  margin-left: 0px !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none !important;
}

.react-pdf__Page {
  min-width: auto !important;
  min-height: auto !important;
}

.time_input > .MuiStack-root {
  padding-top: 0px !important;
  width: 100% !important;
}
.time_input .MuiInputBase-root > input {
  padding: 13.5px 14px !important;
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}
.time_input .MuiInputBase-root > input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.time_input fieldset {
  display: none !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (min-width: 320px) {
  .signature__pad > canvas {
    height: 71px !important;
  }
}
@media (min-width: 480px) {
  .signature__pad > canvas {
    height: 111px !important;
  }
}

@media (min-width: 576px) {
  .signature__pad > canvas {
    height: 138px !important;
  }
}
.toast-success {
  color : darkblue !important
}
.toast-success .Toastify__progress-bar-theme--light {
  background-color: darkblue !important; 
}

.loader {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid white;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

.rdrDateRangePickerWrapper {
  display: inline-flex;
  position: absolute;
}
.select-option:hover{
  background-color: #DEEBFF;
}
.select-option input{
  border-radius: 5px;
}
.select-option{
    display: flex;
    align-items: center;
    justify-items: center;
    gap:0.5rem;
    cursor: pointer;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
}
.zipcode-input::-webkit-inner-spin-button,
.zipcode-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}